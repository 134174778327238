/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import SiteLadingPage from './pages/SiteLadingPage';
import AllAppsPage from './pages/AllAppsPage';
import AppsRouter from './AppsRouter';
import MobileAppsPage from './pages/MobileAppsPage';
import AppInstallerPage from './pages/AppInstallerPage';
import MobileLandingPage from './pages/MobileLandingPage';
import ProfilePage from './pages/ProfilePage';
import { BrokerAppContext } from './contexts/BrokerAppContext';
import BrandsPage from './pages/BrandsPage';
import { APPS_LIST } from './configs';
import AppDetailsPage from './pages/AppDetailsPage';
import Toc from './pages/Toc';
import Privacy from './pages/Privacy';
import About from "./components/HomePageData/index"
import BrandsHome from "./pages/HomeBrandPage"
import CustomSeacrchData from "./components/CutomSearchComponent"
import Pagelanding from './pages/Landingcustom'
const Routes = () => {
  const { isMobile } = useContext(BrokerAppContext);
  const location = useLocation();

  return (
    <>
      <Switch>
      <Route exact path="/" component={Pagelanding} />
      
      {/* <Route
                exact
                path="/"
                render={() => {
                    return (
                      <Redirect to="/explorer" /> 
                  
                    )
                }}
              /> */}
      {/* <Route  path="/about" component={About} /> */}
         <Route exact path="/explorer" component={SiteLadingPage} />
         <Route exact path="/downloadapps" component={SiteLadingPage} />
         <Route exact path="/explorer/:id/:nametab" component={CustomSeacrchData} />
        {/* <Route path="/all-apps" component={AllAppsPage} />
        <Route path="/mobile-apps" component={MobileAppsPage} />
        <Route path="/app-installer/:platform" component={AppInstallerPage} />
        <Route path="/app-installer/" component={AppInstallerPage} />
        <Route path="/mobile-landing" component={MobileLandingPage} /> */}
        {/* <Route path="/apps" component={AppsRouter} /> */}
        {/* <Route path="/toc" component={Toc} />
        <Route path="/privacy" component={Privacy} /> */}
        {/* <Route path="/app" component={AppPageRouter} /> */}
        {/* <Route path="/brokerid/:userName/:tabName" component={ProfilePage} />
        <Route path="/brokerid/:userName" component={ProfilePage} />
        <Route path="/brands" component={BrandsHome} />
        <AppPageRouter /> */}
      </Switch>
      {/* {isMobile && !location?.pathname.includes('brokerid') && (
        <Redirect to="/mobile-landing" />
      )} */}
    </>
  );
};

const AppPageRouter = () => (
  <>
    {APPS_LIST.map((item, index) => (
      <Route
        key={index}
        path={`/app${item.path}`}
        component={(props) => <AppDetailsPage {...props} appData={item} />}
      />
    ))}
  </>
);

export default Routes;
