import React from 'react'
import './landind.scss'
export default function index() {
    return (
        <>
            <div className='landinggpt'>
                <div className='showtext'>
                    <h1>Earngpt.com </h1>
                    <h2> Domain On Sale - 100,000.00 USD</h2>
                </div>
                <p>Email: shorupandomains@gmail.com</p>
            </div>


            <div className='mobilelandinggpt'>
                <div className='showtext'>
                    <h1>Earngpt.com </h1>
                    <h2> Domain On Sale <b4 /> 100,000.00 USD</h2>
                </div>
                <p><span>Email: </span><b4 /> shorupandomains@gmail.com</p>
            </div>

        </>
    )
}
